import React, { useState } from 'react'
import Base from '../components/Base'
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import data from '../properties/login.json'
import { FormElement } from '../skeleton/FormElement'
import { getInput } from '../skeleton/Common'
const Login = () => {
  const [formData, setFormData] = useState<FormElement[]>(data);
  return (
    <Base>
     <Container>
      <Row>
        <Col sm={{size:6,offset:3}}>
        <Card color='dark' inverse>
          <CardHeader>Fill Information To Login</CardHeader>
          <CardBody>
            <Form>
              {formData && formData.map((form:FormElement)=>{
                return (
                  <FormGroup>
                    <Label>{form.label}</Label>
                    <Input 
                    name={form.name}
                    id={form.id}
                    placeholder={form.placeholder}
                    type={getInput(form.type)}
                    />
                </FormGroup>

                )

              })}
              <Container className="text-center">
                <Button color='info'>Login</Button>
                <Button className='ms-2' type='reset'>Reset</Button>
              </Container>
            </Form>
          </CardBody>
        </Card>
        </Col>
      </Row>
     </Container>
    </Base>
  )
}

export default Login
