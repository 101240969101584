import React from 'react'
import Base from '../components/Base'

const Home = () => {
  return (
    <Base>
        <div className="container">
            <h1>This is Home page</h1>
            <p >Lorem, ipsum dolor sit amet consectetur adipisicing elit. Culpa reiciendis quasi velit fugiat, animi at voluptatum nemo illo, sit odit quos ea est quisquam quam, inventore commodi amet quibusdam saepe!</p>
        </div>
    </Base>
  )
}

export default Home
