import axios from "axios";

export const BASE_URL="https://dummyjson.com";

export const services=axios.create({
    baseURL:BASE_URL
})

const TOKEN_BASE_URL="https://dummyjson.com"
export const TOKEN_PATH="/auth/login"
export const token=axios.create({
    baseURL:TOKEN_BASE_URL
})