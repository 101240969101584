import { error } from "console";
import { User } from "../skeleton/User";
import { services } from "./helper"
import { SIGNUP_URL } from "./urls";


export const signUp=(user:User)=>{
   return services.post(SIGNUP_URL,user)
    .then((response)=> response.data)
    .catch((error)=>{
        console.log("Error : ",error)
    });
}