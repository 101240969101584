import React, { ChangeEventHandler, useState } from 'react'
import Base from '../components/Base'
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import { FormElement } from '../skeleton/FormElement';
import data from '../properties/signup.json'
import { getInput } from '../skeleton/Common';
import { signUp } from '../services/user-service';
import { toast } from 'react-toastify';

const SignupForm = () => {
  const [formData,setFormData] =useState({
    name:"",
    email:"",
    mobile:"",
    password:""
  })
  const [formElement, setFormElement] = useState<FormElement[]>(data);
const handleFormData=(value:string,field:string='')=>{
  setFormData({...formData,[field] : value})
}
const notify=(type:string,message:string)=> type==="success"?toast.success(message):
type==="error"? toast.error(message) : toast(message);
const handleSubmit=()=>{
  signUp(formData)
  .then((res)=>{
    notify("success",res.id)
  }).catch((error)=>{
    notify("error",error.message)
  })
}
  return (
    <Base>
     <Container>
      <Row className='mt-5'>
        <Col sm={{size:6,offset:3}}>
          <Card color='dark' inverse>
          <CardHeader>Fill Information To Register</CardHeader>
          <CardBody>
            <Form>
              {formElement && formElement.map((form:FormElement)=>{
                return (
                  <FormGroup>
                    <Label>{form.label}</Label>
                    <Input 
                    name={form.name}
                    id={form.id}
                    placeholder={form.placeholder}
                    type={getInput(form.type)}
                    onChange={(e)=>{handleFormData(e.target.value,form.name)}}
                    />
                </FormGroup>

                )

              })}
              <Container className="text-center">
                <Button color='info' onClick={handleSubmit}>Register</Button>
                <Button className='ms-2' type='reset'>Reset</Button>
              </Container>
            </Form>
          </CardBody>
        </Card>
        </Col>
      </Row>
     </Container>
    </Base>
  )
}

export default SignupForm
