import React from 'react'
import Base from '../components/Base'

const About = () => {
  return (
    <Base>
        <div >
            <h1>This is About Page</h1>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus optio veniam quisquam qui vitae explicabo, tempore cumque ipsa reprehenderit nostrum totam assumenda itaque suscipit magnam. Expedita incidunt quaerat eaque perferendis!
            </p>
        </div>
    </Base>
    
  )
}

export default About
