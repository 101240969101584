import { FC, ReactNode } from "react";
import CustomNavbar from "./CustomNavbar";

export interface Props{
    title?:String
    children?:ReactNode
}

const Base:FC<Props>=({title="Welcome to our Interview hub",children})=>{

    return (
        <div className="container-fluid p-0 m-0">
           <CustomNavbar />
            {children}
            <h1>This is Footer</h1>
        </div>
    )
}

export default Base;